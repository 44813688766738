import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
// import { BgImage } from 'gbimage-bridge'

const BlockCallToActionSection = styled.section`
	padding-top: ${props => props.paddingTop}px;
	padding-bottom: ${props => props.paddingBottom}px;
	width: 100%;
	position: relative;

	&::before {
		content: '';
		background-image: url(${props => props.backgroundImage});
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		position: absolute;
		top: 0 !important;
		width: 100vw !important;
		height: 100% !important;
		left: 50% !important;
		transform: translateX(-50%) !important;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0 !important;
		width: 100vw !important;
		height: 100% !important;
		left: 50% !important;
		transform: translateX(-50%) !important;
	}
`

const BackgroundOpacity = styled.div`
	background-color: ${props => props.color};
	opacity: ${props => props.opacity};
	z-index: 1;
	position: absolute;
	top: 0 !important;
	width: 100vw !important;
	height: 100% !important;
	left: 50% !important;
	transform: translateX(-50%) !important;
`

const Content = styled.div`
	z-index: 2;
	position: relative;
`

const Title = styled.h2`
	color: white;
	margin: 20px 0;
	font-size: 1.8rem;

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		font-size: 2.2rem;
	}
`

const Text = styled.p`
	color: white;
	max-width: 500px;
`

export { BlockCallToActionSection, BackgroundOpacity, Title, Content, Text }
